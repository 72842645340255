@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Baloo+2:wght@400;500;600;700&family=DM+Serif+Text&family=Inter:wght@400;500&family=Quicksand:wght@300;400;600&family=Roboto+Mono:wght@300;400&family=Source+Code+Pro:wght@300;400;600&display=swap');

.navbar{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    text-align: center;
    width: 18%;
    background-color: black;
    background-blend-mode: multiply;
    color: #ffffff;
}


.navCont{
    margin:0 auto 25vh auto;
    padding: 0px;
}

.navCont li{
    list-style: none;
}

.navBrand{
    color: #ffffff;
    text-align: left;
    font-family: 'Baloo 2', cursive;
    font-weight: 600;
    font-size: 2rem;
    margin:20vh auto 10vh auto;
}


 
.a1{  
    display: block;
    margin: 5vh auto 8vh auto;
    text-decoration: none;
    color:lightgray;
    font-family: 'Inter', sans-serif;
    font-weight: 200;
    font-size: 1.2rem;
    padding-top: 5%;
    padding-left: 5%;
    padding-bottom: 1.5vh;
    text-align: center;
    border-bottom: 0.5px solid rgb(204, 196, 196);
    width: 7vw; 
    
}

.active{
    color: #E384FF;
}

@media only screen and (max-width: 480px){
    .navBrand{
        position: absolute;
        visibility: hidden;    
    }
    .navbar{
        display:block;
        width: 100%;
        height: 25px;
    }
    .mobile{
        visibility: hidden;
    }

    .sticky {
        position: fixed;
        top: 0;
    }

    .navCont{
       display: flex;
       justify-content: center;
       align-items: flex-start;
       flex-direction: row;
    }
    
    .navCont li{
        list-style: none;
    }
    
    
     
    .a1{  
        display: inline;
        margin: auto;
        text-decoration: none;
        color:#ffffff;
        font-family: 'Inter', sans-serif;
        font-weight: 200;
        font-size: 10px;
        text-align: center;
        border-bottom: none;
    }
    
    .a1:hover{
      font-size: 10px;
    }
    
    .active{
        color: #E384FF;
        font-size:10px;
    }

   
  }

