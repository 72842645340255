@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Baloo+2:wght@400;500;600;700&family=DM+Serif+Text&family=Inter:wght@400;500&family=Quicksand:wght@300;400;600&family=Roboto+Mono:wght@300;400&family=Source+Code+Pro:wght@300;400;600&display=swap');


.homeCont{
    background-color: #e5e5f7;
    opacity: 1;
    background-image:  linear-gradient(135deg, #fffafc 25%, transparent 25%), linear-gradient(225deg, #fffafc 25%, transparent 25%), linear-gradient(45deg, #fffafc 25%, transparent 25%), linear-gradient(315deg, #fffafc 25%, #e5e5f7 25%);
    background-position:  4px 0, 4px 0, 0 0, 0 0;
    background-size: 8px 8px;
    background-repeat: repeat;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeLeft{
    font-size: 2rem;
    font-weight: 400;
    font-family: 'Source Code Pro', monospace;
    margin-right: 6rem;
    width: 30vw;
}

.namePara{
    margin: 0;
    font-size: 2.5rem;
    font-weight: 400;
    font-family: 'Archivo Black', sans-serif;
}

.typedText{
  color:#E384FF;
  font-weight: 600;
}

.buttons{
    text-align: left;
}

.mobileImg{
    visibility: hidden;
    position: absolute;
}

.buttonDes{
    width: 150px;
    height: 40px;
    margin: 2rem 0.5rem 2rem 0.5rem;
    background-color: #E384FF;
    border: none ;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Source Code Pro', monospace;
    color: white;
}

.buttonDes:hover{
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
    color: black;
    background-color: white;
}

.intro{
    width: 35vw;
}

.divasImg{
    width: 15vw;
    border-radius: 200px;
}

@media only screen and (max-width: 480px){
    .intro {
        margin-top: 50px;
        width: auto;
    }

    .homeCont{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }

    .homeLeft{
        font-size: 1.5rem;
        margin: 20px 0px 20px 30px;
        width: 100%;
    }

    .namePara{
        margin: 0;
        font-size: 2rem;
    }
    
    .divasImg{
       visibility: hidden;
       position:absolute;
    }

    .mobileImg{
        visibility: visible;
        position: sticky;
        width:100vw
    }


}