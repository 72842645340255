.skillCont{
    background-color: #e5e5f7;
    opacity: 1;
    background-image:  linear-gradient(135deg, #fffafc 25%, transparent 25%), linear-gradient(225deg, #fffafc 25%, transparent 25%), linear-gradient(45deg, #fffafc 25%, transparent 25%), linear-gradient(315deg, #fffafc 25%, #e5e5f7 25%);
    background-position:  4px 0, 4px 0, 0 0, 0 0;
    background-size: 8px 8px;
    background-repeat: repeat;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.mainSect{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-evenly;
    justify-content: center;
}

@media only screen and (max-width: 480px){
    .skillCont{
        overflow: scroll;
        height: auto;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .mainSect{
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        flex-wrap: nowrap;
    }

}