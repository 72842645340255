
.skillCardBox{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 12px;
    background-color:white;
    color: gray;
    font-family: 'Quicksand', sans-serif;
    font-weight: 300;
    border: 1px solid lightgray;
    width: 200px;
    height:250px;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
}

.skillCardBox:hover{
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
}

.skillCardImg{
    width: 100px;
}

.exp{
   text-align: center;
   width: 100px;
}

@media only screen and (max-width: 480px){
 .skillCardBox{
   padding-top:10px ;
 }
}