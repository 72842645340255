@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&family=DM+Serif+Text&family=Inter:wght@400;500&family=Quicksand:wght@300;400;600&family=Roboto+Mono:wght@300;400&family=Source+Code+Pro:wght@300;400;600&display=swap');
.App{
  display: flex;
  height: 100vh;
  box-sizing: content-box;
  overflow: hidden;
}

@media only screen and (max-width: 480px){
  .App{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }

}